<template>
    <div>
        <pdf :src="url"></pdf>
    </div>
  </template>

  <script>
    import pdf from "vue-pdf";
  export default {
    components: {
    pdf,
  },
    data() {
      return {
        url: '', // 文件URL
      };
    },
    created() {
        this.url=this.$route.query.url
    },
    mounted() {

    },
    methods: {

    },
  };
  </script>

